export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    return
  }

  const auth = useAuth()
  const toast = useToast()

  if (typeof auth.session === 'undefined') {
    return await new Promise<ReturnType<typeof navigateTo>>((resolve) => {
      watchOnce(
        () => auth.session,
        (value) => {
          if (value === null && to.path !== '/sign-in') {
            toast.add({
              color: 'red',
              title: 'Please sign in to view this page.',
            })

            resolve(navigateTo('/sign-in'))
          }
          else {
            resolve()
          }
        },
        { deep: true },
      )
    })
  }
  else if (auth.session === null && to.path !== '/sign-in') {
    toast.add({
      color: 'red',
      title: 'Please sign in to view this page.',
    })

    return navigateTo('/sign-in')
  }
})
